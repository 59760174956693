import React, { useState } from 'react';

import { PDFDownloadLink } from '@react-pdf/renderer';
import useData from '../../common/useData';
import MultiSelectCCAA from '../common/MultiSelectCCAA';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'
import BasicDateTimePicker from '../fichajes/components/SelectDateRange';
import TemplateInformes2 from './components/TemplateInformes2';
import TemplateInformeRequest from './components/TemplateInformeRequest';
import { ExportCSV } from '../common/Export';
import TemplateInformes from './components/TemplateInformes';

export default function Informes() {
	const { info } = useData();

	const [permisos, setPermisos] = useState([]);
	const [employees, setEmployees] = useState([]);
	const [fichajesErroneos, setFichajesErroneos] = useState([]);
	const [diasNoTrabajados, setDiasNoTrabajados] = useState([]);
	const [downloadReady, setDownloadReady] = useState(false);
	const [downloadReady2, setDownloadReady2] = useState(false);
	const [downloadReady3, setDownloadReady3] = useState(false);
	const [downloadReady4, setDownloadReady4] = useState(false);
	const [tipoInforme, setTipoInforme] = useState('fichajes');
	const [tipoPermiso, setTipoPermiso] = useState('');

	const [data, setData] = useState({
		ccaa: '',
		sede: ''
	});

	const [filterDataInicio, setFilterDataInicio] = useState('');
	const [filterDataFin, setFilterDataFin] = useState(dayjs(new Date()));

	const informeDiasNoTrabajados = async () => {
		setDownloadReady(false);
		setDownloadReady2(false);
		setDownloadReady3(false);
		setDownloadReady4(false);
		setDiasNoTrabajados([]);

		if ((data?.ccaa !== '' && data?.sede !== '') && (filterDataInicio !== '' && filterDataFin !== '')) {
			if (data.ccaa.map(ccaa => ccaa.value).includes('all') || data.sede.map(sede => sede.value).includes('all')) {
				alert("Debes seleccionar una comunidad y una sede concretas, así como un rango de fechas.");
				return;
			}

			if (data.ccaa.length > 1 || data.sede.length > 1) {
				alert("Debes seleccionar una única comunidad y una única sede.");
				return;
			}

			await fetch('https://back.ugt-sp.es/getDiasNoTrabajados', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					dni: info.dni,
					data: data,
					filterDataInicio: filterDataInicio,
					filterDataFin: filterDataFin
				})
			})
				.then((results) => results.json())
				.then((res) => {
					const { fichajes, eventos, requests } = res;

					// calcular numero de dias entre filterDataFin y filterDataInicio
					const dias = dayjs(filterDataFin).diff(filterDataInicio, 'day') + 1;

					let diasNoTrabajados = [];

					for (const empleado of fichajes) {
						const { dni, nombre, apellidos, email, fichajes: fichajesJSON } = empleado;
						if (dni === 'hola') continue;

						const fichajes = JSON.parse(fichajesJSON);
						const requestEmployees = requests.filter(r => r.dni === dni);

						for (let i = 0; i < dias; i++) {
							const fecha = dayjs(filterDataInicio).add(i, 'day').format('YYYY-MM-DD');
							const haveFichaje = fichajes.find(f => dayjs(f.entrada).isSame(fecha, 'day'));

							if (!haveFichaje) {
								const isSundayOrSaturday = dayjs(fecha).day() === 0 || dayjs(fecha).day() === 6;
								const isToday = dayjs(fecha).isSame(dayjs(), 'day');

								if (isSundayOrSaturday || isToday) continue;

								const haveEvento = eventos.find(e => dayjs(e.fechaInicio).isSame(fecha, 'day'));
								const haveStartRequest = requestEmployees.find(r => dayjs(r.startDate, 'day').isSame(fecha, 'day'));
								const haveEndRequest = requestEmployees.find(r => dayjs(fecha, 'day').isSame(dayjs(r.endDate, 'day'), 'day'));
								const haveRequest = requestEmployees.find(r => dayjs(fecha, 'day').isBetween(dayjs(r.startDate, 'day'), dayjs(r.endDate, 'day'), null, '[]'));

								if (haveRequest || haveStartRequest || haveEndRequest) {
									// diasNoTrabajados.push({
									// 	dni,
									// 	nombre,
									// 	apellidos,
									// 	email,
									// 	date: fecha,
									// 	event: false,
									// 	request: true,
									// });
									continue
								} else if (haveEvento) {
									// diasNoTrabajados.push({
									// 	dni,
									// 	nombre,
									// 	apellidos,
									// 	email,
									// 	date: fecha,
									// 	event: true,
									// 	requests: false,
									// 	...haveEvento
									// });
									continue
								} else {
									diasNoTrabajados.push({
										dni,
										nombre,
										apellidos,
										email,
										date: fecha,
										event: false,
										requests: false,
										observaciones: 'Sin evento'
									});
								}
							}
						}
					}

					setDiasNoTrabajados(diasNoTrabajados);
					setDownloadReady4(true);
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			alert("Debes seleccionar una comunidad y una sede concretas, así como un rango de fechas.");
		}
	};

	const errorFichajes = async () => {
		setDownloadReady(false);
		setDownloadReady2(false);
		setDownloadReady3(false);
		setFichajesErroneos([]);

		if (data?.ccaa != '') {
			await fetch('https://back.ugt-sp.es/getErrorFichajes', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					dni: info.dni,
					data: data
				})
			})
				.then((results) => results.json())
				.then((res) => {
					let ccaaValues = data?.ccaa.map((ccaa) => ccaa.value);
					let sedeValues = data?.sede && data?.sede.map((sede) => sede.value);

					let fichajes = [];

					res.fichajes.forEach((fichaje) => {
						if (
							ccaaValues.includes('all') ||
							ccaaValues.includes(fichaje.ccaa)
						) {
							if (sedeValues && sedeValues.length > 0) {
								if (
									sedeValues.includes('all') ||
									sedeValues.includes(fichaje.sede)
								) {
									fichajes.push(fichaje);
								}
							} else {
								fichajes.push(fichaje);
							}
						}
					});

					setFichajesErroneos(fichajes);
					setDownloadReady2(true);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	async function takeData() {
		setDownloadReady(false);
		setDownloadReady2(false);
		setDownloadReady3(false);
		setDownloadReady4(false);

		if (data?.ccaa !== '' || (filterDataInicio !== '' && filterDataFin !== '')) {
			await fetch('https://back.ugt-sp.es/reportFichajesDev', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					dni: info.dni,
					data: data,
					filterDataInicio: filterDataInicio,
					filterDataFin: filterDataFin
				})
			})
				.then((results) => {
					return results.json();
				})
				.then((data) => {
					if (data?.error) {
						alert("Error al generar el informe, por favor, inténtelo de nuevo.");
						return;
					}
					let tempFichajes = [];

					for (const i in data?.fichajes) {
						if (Object.hasOwnProperty.call(data?.fichajes, i)) {
							const element = data?.fichajes[i];
							tempFichajes.push(element);
						}
					}

					setEmployees(tempFichajes);
					setDownloadReady3(true);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}

	async function takePermisos() {
		setDownloadReady(false);
		setDownloadReady2(false);
		setDownloadReady3(false);
		setDownloadReady4(false);

		let ccaaValues = data?.ccaa && data?.ccaa?.map((ccaa) => ccaa.value) || [];
		let sedeValues = data?.sede && data?.sede?.map((sede) => sede.value) || [];

		if (ccaaValues.includes('all')) {
			ccaaValues = [];
		}

		if (sedeValues.includes('all')) {
			sedeValues = [];
		}

		await fetch('https://back.ugt-sp.es/reportPermisos', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				tipoPermiso, ccaaValues, sedeValues
			})
		})
			.then((results) => {
				return results.json();
			})
			.then((data) => {
				if (!data.error) setPermisos(data.permisos?.map(p => {
					return { ...p, requestType: p.requestType == 'vacaciones' ? 'Vacaciones' : p.requestType == 'diasPropios' ? 'Días Propios' : p.requestType == 'otrosMotivos' ? 'Otros' : 'Todos' }
				}));

			})
			.catch((error) => {
				console.log(error);
			});

		setDownloadReady(true);
	}


	return (
		<div className='d-flex align-items-center justify-content-center w-100 h-100 scale-in'>
			<div className='card' style={{ minWidth: '500px' }}>
				<div className='card-body'>
					<h4>Generar Informe</h4>

					<div className='d-flex gap-4'>
						<select
							className='form-select w-50'
							placeholder='Tipo de informe'
							value={tipoInforme}
							onChange={(e) => setTipoInforme(e.target.value)}
						>
							<option value='fichajes'>Fichajes</option>
							<option value='errorFichajes'>Fichajes erroneos</option>
							<option value='permisos'>Permisos</option>
							<option value='diasNoTrabajados'>Días no trabajados</option>
						</select>
						{tipoInforme === 'diasNoTrabajados' ? (
							<span className='text-muted'>* Es obligatorio elegir una comunidad y sede.</span>
						) : null}

						{tipoInforme === 'permisos' ? (
							<select
								className='form-select w-50'
								placeholder='Tipo de informe'
								onChange={(e) => setTipoPermiso(e.target.value)}
							>
								<option value=''>Tipo de permiso</option>
								<option value='vacaciones'>Vacaciones</option>
								<option value='diasPropios'>Días Propios</option>
								<option value='otrosMotivos'>Otros</option>
								<option value=''>Todos</option>
							</select>
						) : null}
					</div>

					<MultiSelectCCAA
						data={data}
						dataType='filtro'
						changeFunction={setData}
					/>

					{tipoInforme === 'fichajes' || tipoInforme === 'diasNoTrabajados' ? (
						<>
							<BasicDateTimePicker
								type='inicio'
								changeFunction={setFilterDataInicio}
							/>
							<BasicDateTimePicker
								type='fin'
								changeFunction={setFilterDataFin}
							/>
						</>
					) : null}

					<div className='w-100'>
						<button
							className='btn btn-primary w-100 mt-2'
							onClick={() => {
								console.log(tipoInforme)
								if (tipoInforme === 'fichajes') takeData();
								else if (tipoInforme === 'errorFichajes') errorFichajes();
								else if (tipoInforme === 'permisos') takePermisos();
								else if (tipoInforme === 'diasNoTrabajados') informeDiasNoTrabajados();
							}}
						>
							Consulta
						</button>
					</div>

					{downloadReady ? (
						<>
							{/* <PDFDownloadLink document={
								<TemplateInformes2 info={employees} title="Trabajadores" />} fileName="listado_trabajadores.pdf">
								{({ blob, url, loading, error }) =>
									loading ? 'Loading document...' : 'Descargar informe'
								}
							</PDFDownloadLink> */}

							<PDFDownloadLink
								document={
									<TemplateInformeRequest
										info={permisos}
										title={'Trabajadores '}
									/>
								}
								fileName='listado_trabajadores.pdf'
								style={{
									display: 'block',
									padding: '20px 0',
									textTransform: 'uppercase'
								}}
								className='btn btn-secondary mt-2'
								onClick={(event) => {
									event.preventDefault();
									window.open(event.target.href, '_blank');
									setDownloadReady(false);
								}}
							>
								{({ blob, url, loading, error }) =>
									loading ? 'Cargando informe...' : 'Abrir informe'
								}
							</PDFDownloadLink>
						</>
					) : null}

					{downloadReady2 ? (
						<>
							{/* <PDFDownloadLink document={<TemplateInformes info={employees} title="Trabajadores" />} fileName="listado_trabajadores.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading document...' : 'Descargar informe'
                            }
                        </PDFDownloadLink> */}
							<ExportCSV name='EXPORTAR' className='w-100 mt-2' csvData={fichajesErroneos.map(f => {
								return {
									'DNI': f.dni,
									'Email': f.email,
									'Nombre': f.nombre,
									'Apellidos': f.apellidos,
									'CCAA': f.ccaa,
									'Sede': f.sede,
									'Entrada': new Date(f.entrada).toLocaleString({ language: 'es-ES' }),
									'Salida': new Date(f.salida).toLocaleString({ language: 'es-ES' }),
									'Horas': f.horas,
									'Observaciones': f.observaciones_salida
								}
							})} fileName={`fichajes-erroneos`} />

							<PDFDownloadLink
								document={
									<TemplateInformes2
										info={fichajesErroneos}
										title={'Fichajes erroneos'}
									/>
								}
								fileName='listado_trabajadores.pdf'
								style={{
									display: 'block',
									padding: '20px 0',
									textTransform: 'uppercase'
								}}
								className='btn btn-secondary mt-2'
								onClick={(event) => {
									event.preventDefault();
									window.open(event.target.href, '_blank');
									setDownloadReady2(false);
								}}
							>
								{({ blob, url, loading, error }) =>
									loading ? 'Cargando informe...' : 'Abrir informe'
								}
							</PDFDownloadLink>
						</>
					) : null}

					{downloadReady3 ? (
						<>
							{/* <PDFDownloadLink document={<TemplateInformes info={employees} title="Trabajadores" />} fileName="listado_trabajadores.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading document...' : 'Descargar informe'
                            }
                        </PDFDownloadLink> */}
							{/* <ExportCSV name='EXPORTAR' className='w-100 mt-2' csvData={employees.map(f => {
								return {
									'DNI': f.dni,
									'Email': f.email,
									'Nombre': f.nombre,
									'Apellidos': f.apellidos,
									'CCAA': f.ccaa,
									'Sede': f.sede,
									'Entrada': new Date(f.entrada).toLocaleString({ language: 'es-ES' }),
									'Salida': new Date(f.salida).toLocaleString({ language: 'es-ES' }),
									'Horas': f.horas,
									'Observaciones': f.observaciones_salida
								}
							})} fileName={`fichajes`} /> */}

							<PDFDownloadLink
								document={
									<TemplateInformes
										info={employees}
										ccaa={"Todas"}
										title={'Fichajes'}
										fileName='listado_trabajadores.pdf'
										inicio={filterDataInicio}
										fin={filterDataFin}
									/>
								}
								fileName='listado_fichajes.pdf'
								style={{
									display: 'block',
									padding: '20px 0',
									textTransform: 'uppercase'
								}}
								className='btn btn-secondary mt-2'
								onClick={(event) => {
									event.preventDefault();
									window.open(event.target.href, '_blank');
									setDownloadReady2(false);
								}}
							>
								{({ blob, url, loading, error }) =>
									loading ? 'Cargando informe...' : 'Abrir informe'
								}
							</PDFDownloadLink>
						</>
					) : null}
					{downloadReady4 ? (
						<>
							<PDFDownloadLink
								document={
									<TemplateInformes2
										info={
											diasNoTrabajados.sort((a, b) => {
												// Primero ordenar por DNI (string)
												const dniComparison = a.dni.localeCompare(b.dni);

												if (dniComparison !== 0) {
													return dniComparison;
												}

												// Si los DNI son iguales, ordenar por fecha (más antiguo primero)
												return new Date(a.date).getTime() - new Date(b.date).getTime();
											})
										}
										title={'Días no trabajados'}
										noWork
									/>
								}
								fileName='listado_dias_no_trabajados.pdf'
								style={{
									display: 'block',
									padding: '20px 0',
									textTransform: 'uppercase'
								}}
								className='btn btn-secondary mt-2'
								onClick={(event) => {
									event.preventDefault();
									window.open(event.target.href, '_blank');
									setDownloadReady4(false);
								}}
							>
								{({ blob, url, loading, error }) =>
									loading ? 'Cargando informe...' : 'Abrir informe'
								}
							</PDFDownloadLink>

							<ExportCSV name='EXPORTAR' className='w-100 mt-2' csvData={diasNoTrabajados.map(f => {
								return {
									'DNI': f.dni,
									'Email': f.email,
									'Nombre': f.nombre,
									'Apellidos': f.apellidos,
									'Fecha': new Date(f.date).toLocaleDateString()
								}
							}).sort((a, b) => {
								// Primero ordenar por DNI (string)
								const dniComparison = a.DNI.localeCompare(b.DNI);

								if (dniComparison !== 0) {
									return dniComparison;
								}

								// Si los DNI son iguales, ordenar por fecha (más antiguo primero)
								return new Date(a.date).getTime() - new Date(b.date).getTime();
							})} fileName={`dias-no-trabajados`} />
						</>
					) : null}
				</div>
			</div>

			{/* <PDFViewer style={{width: "100%", height:"100vh"}}>
                <TemplateInformes data={employees} title={"Trabajadores UGT"} />
            </PDFViewer> */}
		</div>
	);
}
